<template>
  <div class="lesson_detail">
    <!-- <headers></headers> -->
    <div class="detail_wrapper manage-wrapper">
      <!-- <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/manage/project' }">项目课管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/lesson/' + form.courseProjectId }">课节管理</el-breadcrumb-item>
        <el-breadcrumb-item>课节详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="tabs__header detail-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="课节详情" name="detail">
            <el-form ref="form" :model="form" label-width="180px">
              <el-form-item label="课节名称：" :required="true" v-if="course.type != 5">
                <p>{{ form.name || '无'}}</p>
              </el-form-item>
              <el-form-item label="课节名称：" :required="true" v-if="course.type == 5">
                <p>{{ form.lessonName || '无'}}</p>
              </el-form-item>
              <!-- 大班普通课、小班课 -->
              <el-form-item label="上课时间：" :required="true"
                v-if="course.type == 2 || course.type == 4 || course.type == 5 || course.type == 6">
                <p>
                  {{ dayjs(form.lessonsBeginTime*1000).format('YYYY-MM-DD HH:mm:ss') }} -
                  {{ dayjs(form.lessonsEndTime*1000).format('HH:mm:ss') }}
                </p>
              </el-form-item>
              <!-- 大班伪直播、异步课 -->
              <el-form-item label="开放时间：" :required="true" v-if="course.type == 3 || course.pattern == 1">
                <p>{{ form.lessonsBeginDate || '无'}}</p>
              </el-form-item>

              <div class="teahcers flex-align" v-if="$route.query.courseType !=5 && $route.query.lessonsPattern == 0">
                <el-form-item label="授课老师：" :required="true">
                  <p>{{ form.teacher? form.teacher.name : '无'  }}</p>
                </el-form-item>
                <el-form-item label="助教老师：">
                  <p>{{ form.assistant? form.assistant.name : '无' }}</p>
                </el-form-item>
              </div>

              <div class="teahcers flex-align" v-if="$route.query.courseType ==5">
                <el-form-item label="授课老师：" :required="true">
                  <p>{{ form.teacherName || '无'  }}</p>
                </el-form-item>
                <el-form-item label="助教老师：">
                  <p>{{ form.assistantName || '无' }}</p>
                </el-form-item>
              </div>

              <el-form-item label="主讲教师：" class="teahcers" v-if="$route.query.lessonsPattern == 1">
                {{form.teachName||'未知'}}
              </el-form-item>


              <!-- 小班课 -->
              <div class="flex-align" v-if="course.type == 4">
                <el-form-item label="台上学生：" :required="true">
                  <p>{{ `1 vs ${form.maxUsers}` || '无' }}</p>
                </el-form-item>
                <el-form-item label="台下学生：">
                  <p>{{ form.maxBackupUsers || 0 }}</p>
                </el-form-item>
                <el-form-item label="学生自动上台：">
                  <p>{{ form.autoOnStage? '是' : '否' }}</p>
                </el-form-item>
              </div>

              <!-- 大班普通课 -->
              <el-form-item label="PC端直播面板：" :required="true" v-if="course.type == 2 || course.type == 3">
                <p>{{ form.isVideoMain === 1 ? "以视频为主" : "以PPT为主" }}</p>
              </el-form-item>
              <el-form-item label="手机端直播面板：" :required="true" v-if="course.type == 2 || course.type == 3">
                <p>{{ form.mobileIsVideoMain === 1 ? "以视频为主" : "以PPT为主" }}</p>
              </el-form-item>


              <!-- classin标准课 -->
              <el-form-item label="教学模式：" :required="false" v-if="course.type == 5">
                <p>{{ form.teachMode === 1 ? "在线教室" : "智慧教室" }}</p>
              </el-form-item>
              <el-form-item label="台上人数：" v-if="course.type == 5">
                <p>1v{{ form.platformCount }} <span v-if="form.isHd">({{form.isHd === 1 ? '高清':'全高清'}})</span></p>
              </el-form-item>
              <el-form-item label="学生自动上台：" v-if="course.type == 5">
                <p>{{ form.isAutoOnstage? '否' : '是' }}</p>
              </el-form-item>
              <!-- <el-form-item label="直播回放：">
                  <p>{{ form.isAutoOnStage? '否' : '是' }}</p>
              </el-form-item> -->


              <!-- 大班普通课、大班伪直播、小班课 -->
              <el-form-item label="直播课件：" :required="course.type != 3"
                v-if="course.pattern != 1 && (course.type != 5 && course.type != 6)">
                <p v-if="!form.liveFileNames">无</p>
                <ul class="zhibo_list" v-if="form.liveFileNames">
                  <li style="" v-for="(item, index) in form.liveFileNames" :key="index" class="csp">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item label="课节附件：">
                <p v-if="!form.lessonFileNames">无</p>
                <ul class="zhibo_list" v-if="form.lessonFileNames">
                  <li class="csp" v-for="(item, index) in form.lessonFileNames" :key="index" @click="openFile(item)">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item label="点播视频：" :required="true" v-if="course.type == 3 ">
                <p v-if="!form.vodName">无</p>
                <ul class="zhibo_list" v-if="form.vodName">
                  <li class="csp" v-for="(item, index) in form.vodName" :key="index" @click="openFile(item)">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item :label="course.pattern == 0? '回放视频：':'视频：'" :required="course.pattern == 1"
                v-if="course.type != 5 && course.type != 6">
                <p v-if="!form.videoNames">无</p>
                <ul class="zhibo_list el-col el-col-20" v-if="form.videoNames">
                  <li class="csp" style="" v-for="(item, index) in form.videoNames" :key="index">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>

              <el-form-item class="el-form-item-btns" label-width="0px">
                <el-button type="primary" @click="goEdit">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {},
        course: {},
        activeName: 'detail'
      };
    },
    methods: {
      // 去编辑页
      goEdit() {
        let id = this.$route.params.id;
        let path = ''
        let typeMap = {
          '2': 'large',
          '3': 'large',
          '4': 'small',
          '5': 'classin',
          '6': 'tencent'
        }
        path = this.$route.query.lessonsPattern == 0 ? typeMap[this.$route.query.courseType] : 'async'

        this.$router.push({
          path: `/lesson/edit/${path}/${id}`,
          query: {
            lessonsPattern: this.$route.query.lessonsPattern,
            courseType: this.$route.query.courseType,
            breadNumber: 5,
          }
        });

      },
      // 请求课节信息
      async getLessonInfo() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getLesson(id);
        // console.log(resData);
        if (!resData) return
        this.form = resData.data;
        if (this.form.vodName) {
          let arr = [];
          arr.push(this.form.vodName)
          this.form.vodName = arr
        }
        // console.log(this.form);
      },
      // 预览、下载文件
      openFile(item) {
        window.open(this.downloadURL + item.identifier)
      },
      // 页面初始化
      init() {
        window.scroll(0, 0)
        this.getLessonInfo();
        this.course = {
          pattern: this.$route.query.lessonsPattern,
          type: this.$route.query.courseType
        }
      }
    },
    mounted() {
      this.init();
    },
    activated() {
      this.init();
    }
  };
</script>

<style lang="less" scoped>
  @import "../less/lesson_commen.less";

  .lesson_detail {
    .el-form-item {
      margin-bottom: 15px;
    }

    .cover {
      margin-top: 10px;
      display: block;
      width: 193px;
      height: 125px;
      background-color: palevioletred;
    }

    .teahcers .el-form-item:last-child {
      margin-left: 130px;
    }

    /deep/ .el-form-item-btns {
      margin: 52px 0 34px;

      .el-button {
        margin-left: 0px;
        font-size: 13px;
        line-height: 31px;
        padding: 0 47px;
        color: #fff;
        background-color: #508EF9;
      }

      @media screen and (min-width:1250px) {
        .el-button {
          font-size: 16px;
          line-height: 38px;
          padding: 0 57px;
        }
      }
    }

    .zhibo_list {
      li {
        display: inline-block;
        line-height: 32px;

        margin: 13px 20px 5px 0;
        border-radius: 4px;
        padding: 0px 12px;

        color: #666;
        border: 1px solid rgba(204, 204, 204, .22);

        .deletefile {
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }


  }
</style>